/** @format */

import { inject, customElement, computedFrom, bindable } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import reportsSchedulesService, { SchedulePeriods } from 'services/api/reportsSchedulesService'

import { PromptDialog } from 'components/prompt-dialog/prompt-dialog';
import reportsService from 'services/api/reportsService';
import { TranslationService } from 'services/translationService';

import './reports-schedules.scss';

@customElement('report-schedule-details')
@inject(
  Element,
  TranslationService,
  DialogService
)
export class ReportScheduleDetails {
  @bindable onCancel;

  constructor(
    element,
    _TranslationService,
    _DialogService
  ) {
    this.element = element;
    this.translationService = _TranslationService;
    this.dialogService = _DialogService;
    //
    this.scheduleService = reportsSchedulesService;
    this.reportsService = reportsService;
    //

    this.loading = false;
    this.errorMessage = null;
    this.schedule = null;
    this.schedulePeriods = SchedulePeriods;
    this.reports = [];
    this.reportType = null;

    this.reportSettings = {};
    // this.onPeriodChanged = this.onPeriodChanged.bind(this);
    this.onReportTypeChanged = this.onReportTypeChanged.bind(this);
    this.onOwnerChanged = this.onOwnerChanged.bind(this);
    //validation
  }

  @computedFrom('schedule.periodicity')
  get showSettingsDates() {
    return this.schedule.periodicity === SchedulePeriods.once;
  }

  activate(schedule) {
    this.schedule = schedule;
    this.loadReports().then(() => {
      this.loadSchedule();
    });
  }

  onReportTypeChanged(report) {
    if (this.schedule) {
      this.reportSettings = { reportId: report.id };
    }
  }

  onOwnerChanged(owner) {
    this.schedule.owner = owner;
  }

  loadReports(id) {
    return this.reportsService.loadReports().then(reports => {
      this.reports = reports.map(r => {
        r.name = this.translationService.getCap(r.name);
        r.selected = r.id === id;
        return r;
      });
    });
  }

  selectReport(id) {
    this.reports = this.reports.map(r => {
      r.selected = r.id === id;
      return r;
    });
  }

  loadSchedule() {
    if (this.schedule && this.schedule.id) {
      this.loading = true;
      this.scheduleService.get(this.schedule.id).then(schedule => {
        this.schedule = schedule;
        this.reportSettings = Object.assign({}, schedule.settings);
        this.selectReport(schedule.settings.reportId);
        this.loading = false;
      });
      return;
    }
    if (this.reports) {
      let report = this.reports.find(x => x.selected) || this.reports[0];
      this.reportSettings = { reportId: report.id };
    }
  }

  close(refreshList) {
    var event = new CustomEvent('close', { 
      detail: {
        refreshList
      },
      bubbles: true
    });

    this.element.dispatchEvent(event);
  }

  onSave() {
    this.schedule.settings = this.reportSettings;

    this.validate().then(success => {
      if (success) {
        this.loading = true;
        this.scheduleService
          .update(this.schedule)
          .then(_schedule => {
            this.loading = false;
            this.close(true)
          })
          .catch(err => {
            this.errorMessage = err.message;
            this.loading = false;
          });
      }
    });
  }

  onDelete() {
    this.openPrompt().then(confirm => {
      if (!confirm) return;
      this.loading = true;
      this.scheduleService
        .delete(this.schedule)
        .then(() => {
          this.loading = false;
          this.schedule.deleted = true;
          this.close(true)
        })
        .catch(err => {
          this.errorMessage = err.message;
          this.loading = false;
        });
    });
  }

  validate() {
    return new Promise(res => {
      if (!this.schedule.settings.emailSettings.addresses.length) {
        // this.errorMessage = 'report_emails_mandatory';
        this.schedule.settings.error = 'report_emails_mandatory';
        res(false);
      }
      res(true);
    });
  }

  openPrompt() {
    const title = 'delete_scheduled_report';
    const question = true;
    const message = 'delete_scheduled_report_message';
    const confirm = 'delete';

    return new Promise(resolve => {
      this.dialogService
        .open({ viewModel: PromptDialog, model: { title, question, message, confirm} })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }
}
