/** @format */

import {
  Router
} from 'aurelia-router';
import {
  inject,
  observable
} from 'aurelia-framework';
import reportsService from 'services/api/reportsService';
import {
  TranslationService
} from 'services/translationService';
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';

import './reports.scss';

@inject(Router, TranslationService)
export class Reports {

  constructor(_Router, _TranslationService) {
    this.reportsService = reportsService;
    this.router = _Router;
    this.translations = _TranslationService;

    this.selectedReport = null;
    this.fullScreen = false;
    this.isGenerating = false;
    this.activeDialogAction = null;

    this.queueLoadingId = null;

    this.onReportsQueueSelect = this.onReportsQueueSelect.bind(this);
    this.onReportCreateConfirm = this.onReportCreateConfirm.bind(this);
    this.onReportViewClose = this.onReportViewClose.bind(this);

    this.onReportLink = this.onReportLink.bind(this);
    this.onReportPrint = this.onReportPrint.bind(this);
    this.onActionComplete = this.onActionComplete.bind(this);

    this.onKeyDown = this.onKeyDown.bind(this);
  }

  attached() {
    window.addEventListener('keydown', this.onKeyDown);
    this.queueLoadingId = this.router.currentInstruction.params.childRoute || this.router.currentInstruction.queryParams.id;
  }

  detached() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(e) {
    if (e.keyCode !== 27) return;

    if (this.displayReport && !this.isGenerating) {
      this.onReportViewClose();
    }
  }

  onReportCreateConfirm(settings, force = false) {
    this.generateReport(settings, force);
  }

  generateReport(settings, force = false) {
    this.isGenerating = true;
    this.queueLoadingId = null;
    this.reportsService
      .createExecution(settings.reportId, settings, force)
      .then(exec => {
        this.queueLoadingId = exec.id;
        this.selectedReport = null;
      });
  }

  onReportsQueueSelect(report) {
    if (!report || !report.id) return;

    this.isGenerating = true;
    this.displayReport = null;
    this.queueLoadingId = null;

    this.reportsService
      .getReportData(report.id)
      .then(_report => {
        this.displayReport = _report;
        this.displayReport.link = report.link;
        this.isGenerating = false;
      })
      .catch(() => (this.isGenerating = false));
  }

  onReportViewClose() {
    if (this.activeDialogAction) {
      this.activeDialogAction = null;
    } else {
      this.creatingReportId = null;
      this.displayReport = null;
    }
  }

  onActionComplete(action, value) {
    if (!action) this.onReportViewClose();
    //update name for viewer
    if (action === 'rename') {
      this.displayReport.reportName = value;
    }
    if (action === 'modify') {
      this.onReportViewClose();
      this.selectedReport = value.settings;
    }
  }

  onReportLink(reportId, context, data) {
    // prettier-ignore
    let reportLinksSettings = {
      5: {
        id: 1,
        dates: () => ({
          from: DTU.startOf(data.date),
          to: DTU.endOf(data.date)
        })
      },
      1: {
        id: 3,
        dates: () => ({
          from: DTU.add(data.startGpsTimestamp, -1, 'second'),
          to: DTU.add(data.endGpsTimestamp, 1, 'second')
        })
      },
      7: {
        id: 8,
        dates: () => ({
          from: DTU.add(data.startGpsTimestamp, -1, 'second'),
          to: DTU.add(data.endGpsTimestamp, 1, 'second')
        })
      },
      13: {
        id: 14,
        dates: () => ({
          from: data.dates.from,
          to: data.dates.to
        })
      }
    };

    let reportLink = reportLinksSettings[reportId];
    if (reportLink) {
      let settings = {
        dates: reportLink.dates(),
        contextType: context.contextType,
        context: [context.id],
        customSettings: data.customSettings
      };

      let newWin = window.open('', '_blank');
      if (newWin) {
        newWin.document.body.innerHTML =
          '<div style="font-size:18px; font-family: Helvetica;">Preparing report...</div>';

        this.reportsService
          .createExecution(reportLink.id, settings)
          .then(exec => {
            let url = `${window.location.origin}/#/reports/${exec.id}`;
            newWin.location = url;
          });
      }
    }
  }

  onReportPrint() {
    window.print();
  }
}
