/** @format */

import {
  bindable,
  observable
} from 'aurelia-framework';
import reportsService from 'services/api/reportsService';

export class ReportBarActions {
  @bindable onComplete;
  @bindable report;
  @bindable dialogAction;

  constructor() {
    this.reportsService = reportsService;
    this.actions = [{
        id: 'rename',
        placeholder: 'rename',
        dialog: true,
        value: null,
        el: null,
        action: this.renameReport.bind(this),
        order: 4
      },
      {
        id: 'share',
        placeholder: 'share_report',
        dialog: true,
        order: 3,
        subActions: {
          copy: {
            id: 'copy',
            value: null,
            el: null,
            action: this.copyLink.bind(this),
          },
          email: {
            id: 'email',
            value: null,
            el: null,
            action: this.sendEmail.bind(this),
          }
        }
      },
      {
        id: 'download',
        placeholder: 'download_report',
        dialog: true,
        order: 2,
        subActions: {
          csv: {
            id: 'csv',
            placeholder: 'CSV',
            action: this.exportCSV.bind(this),
          },
          pdf: {
            id: 'pdf',
            placeholder: 'pdf',
            action: this.exportPDF.bind(this),
          }
        }
      },
      {
        id: 'modify',
        placeholder: 'modify_report',
        action: this.modifyReport.bind(this),
        order: 1
      },
      {
        id: 'close',
        placeholder: 'close',
        order: 0
      }
    ];

    this.dialogAction = null;
    this.onDialogComplete = this.onDialogComplete.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  updateActionValue(id, value) {
    return ((this.actions.find(x => x.id === id) || {}).value = value);
  }

  reportChanged(report) {
    this.dialogAction = null;
    if (report) {
      this.updateActionValue('rename', report.reportName);
    }
  }

  dialogActionChanged() {
    if (this.report) {
      var action = this.actions.find(x => x.id === 'share')
      if (action.subActions && action.subActions.copy) {
        action.subActions.copy.value = this.report.link;
      }
      if (action.subActions && action.subActions.email) {
        action.subActions.email.value = null;
      }
    }
  }

  onClick(action) {
    if (action.action || action.dialog) {
      if (action.dialog) {
        this.dialogAction = action != this.dialogAction ? action : null;
      } else {
        action.action();
      }
      return;
    }
    this.onDialogComplete(null);
  }

  copyLink(action) {
    if (action.el) action.el.select();
    try {
      document.execCommand('copy');
    } catch (e) {}
    return Promise.resolve(action);
  }

  sendEmail(action) {
    if (action && action.value && action.value.length > 0) {
      let result = this.reportsService.sendByEmail(this.report.id, action.value);
      action.value = null;
      return result;
    }
  }

  exportReport(media) {
    if (this.report && this.report.id && media) {
      let link = this.reportsService.getReportUrlToExport(this.report.id, media);
      window.open(link);
    }
  }

  exportCSV() {
    this.exportReport('csv');
  }

  exportPDF() {
    this.exportReport('pdf');
  }

  modifyReport() {
    if (this.report && this.report.id) {
      this.onComplete('modify', this.report);
    }
  }

  renameReport(action) {
    if (this.report && this.report.id) {
      return this.reportsService
        .renameExecution(this.report.id, action.value)
        .then(name => {
          this.report.reportName = name;
          this.updateActionValue('rename', name);
          return Promise.resolve(name);
        });
    }
  }

  onDialogComplete(value) {
    if (!value) {
      this.onComplete(null, value);
    } else {
      let id = (this.dialogAction || {}).id;
      this.dialogAction = undefined;
      this.onComplete(id, value);
    }
  }
}
