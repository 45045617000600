import {
  inject
} from 'aurelia-framework';
import reportsSchedulesService, {
  ReportSchedule
} from 'services/api/reportsSchedulesService'
import {
  DialogService
} from 'aurelia-dialog';
import {
  ReportScheduleDetails
} from './report-schedule-details';

import './reports-schedules.scss';

@inject(DialogService)
export class ReportsSchedules {
  constructor(_DialogService) {
    this.dialogService = _DialogService;
    this.schedulesService = reportsSchedulesService;

    this.gridHeaders = [{
        label: 'report',
        sortable: true,
        sortField: 'displayName'
      },
      {
        label: 'periodicity',
        sortable: true
      },
      {
        label: 'last_execution',
        sortable: true,
        sortField: 'lastExecutedLocal'
      },
      {
        label: 'next_execution',
        sortable: true,
        sortField: 'nextExecutionLocal'
      }
    ];

    this.gridColumns = [{
        type: 'text',
        field: 'displayName',
        translate: true
      },
      {
        type: 'text',
        field: 'periodicity',
        translate: true
      },
      {
        type: 'date',
        field: 'lastExecutedLocal',
        format: 'DATE'
      },
      {
        type: 'date',
        field: 'nextExecutionLocal',
        format: 'DATE'
      },
    ];

    this.sortProperties = {
      field: 'displayName',
      reverse: false
    }

    this.schedules = [];
    this.loading = false;
    this.reportsScheduleModel = null;
    this.onScheduleSelected = this.onScheduleSelected.bind(this);
    this.onNewSchedule = this.onNewSchedule.bind(this);
  }

  attached() {
    this.loadSchedules()
  }

  detached() {
    this.reportsScheduleModel = null; 
  }

  loadSchedules() {
    this.loading = true;
    this.schedulesService.get()
      .then(scheds => {
        this.schedules = scheds;
        this.loading = false;
      })
  }

  onScheduleSelected(schedule) {
    this.openScheduleDetails(schedule);
  }

  onNewSchedule() {
    this.openScheduleDetails();
  }

  openScheduleDetails(schedule) {
    this.reportsScheduleModel = new ReportSchedule(schedule);
  }

  closeFromScheduleDetails(event) {
    if (event.detail && event.detail.refreshList) {
      this.loadSchedules();
    }

    this.reportsScheduleModel = null;
  }
}
