/** @format */

import { bindable, computedFrom } from 'aurelia-framework';
import reportsService from 'services/api/reportsService';
import './reports-create.scss';

const ICONS = {
  'current-status': require('./icons/current-status.svg'),
  'day-summary': require('./icons/day-summary.svg'),
  'overspeed-summary': require('./icons/overspeed-summary.svg'),
  'overspeed-detailed': require('./icons/overspeed-detailed.svg'),
  'journey-summary': require('./icons/journey-summary.svg'),
  'journey-detailed': require('./icons/journey-detailed.svg'),
  'ecodriving-summary': require('./icons/ecodriving-summary.svg'),
  'ecodriving-detailed': require('./icons/ecodriving-detailed.svg'),
  'odometer-and-chronometer': require('./icons/odometer-and-chronometer.svg'),
  'out-of-hours': require('./icons/out-of-hours.svg'),
  'parked-time': require('./icons/parked-time.svg'),
  'vehicle-summary': require('./icons/vehicle-summary.svg'),
  'working-hours-summary': require('./icons/working-hours-summary.svg'),
  'working-hours-detailed': require('./icons/working-hours-detailed.svg'),
  'billing-summary': require('./icons/billing-summary.svg'),
  'licencecheck-summary': require('./icons/licencecheck-summary.svg'),
  'licencecheck-detailed': require('./icons/licencecheck-summary.svg')
};

export class ReportsCreate {
  @bindable onConfirm;

  //use to autoselect/deselect the current one
  @bindable selectedId;
  @bindable selectedReportData;

  constructor() {
    this.reportService = reportsService;
    this.reports = [];
    this.selectedReport = false;
    this.selectedReportData = null;
    this.selectedId = null;

    this.settings = {};
  }

  @computedFrom('settings.context', 'settings.error')
  get valid() {
    let context = this.settings.context || [];
    return context.length && !this.settings.error;
  }

  selectedIdChanged(id) {
    if (id !== (this.selectedReport && this.selectedReport.id))
      this.onReportSelect(this.reports.find(x => x.id === id));
  }

  attached() {
    const toView = r => {
      r.icon = ICONS[r.type];
      return r;
    };
    this.reportService.loadReports().then(reports => {
      this.reports = reports.map(toView);
      if (this.selectedReportData) {
        this.onReportSelect(this.reports.find(x => x.id === this.selectedReportData.reportId));
      }
    });
  }

  detached() {
    this.reports = [];
    this.selectedReport = false;
    this.selectedId = null;
    this.selectedReportData = null;

    this.settings = {};
  }

  onReportSelect(report) {
    this.selectedReport = report;
    this.selectedId = report && report.id;

    if (report) {
      if (this.selectedReportData) {
        this.settings = this.selectedReportData
      } else {
        this.settings = { reportId: report.id };
      }
    } else {
      this.selectedReportData = null;
    }
  }

  onSettingsClose() {
    this.onReportSelect(null);
  }

  onDone() {
    let forceAction = (this.selectedReportData) ? true : false;
    this.onConfirm(this.settings, forceAction);
  }
}
