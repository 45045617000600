/** @format */

import {
  bindable
} from 'aurelia-framework';

import LOG from 'services/loggerServices';

import './report-action-dialog.scss';

export class ReportActionDialog {
  @bindable action;
  @bindable onComplete;

  constructor() {
    this.reset();
    this.offset = 0;
    this.subAction = null;
  }

  actionChanged(action) {
    this.reset();
    if (!action) {
      return;
    }
    this.offset = (105 * (action.order || 1)) + 15;
  }

  onClick(subAction) {
    this.subAction = subAction
    if (typeof this.subAction.action !== 'function') {
      LOG.warn('report action dialog: no valid action found');
      return;
    }

    this.loading = true;
    Promise.resolve(this.subAction.action(this.subAction))
      .then(() => {
        this.loading = false;
        this.success = true;
        this.submitTimeout = setTimeout(() => {
          this.onComplete(this.subAction);
        }, 1000);
      })
      .catch(() => (this.loading = false)); //todo error state/catch
  }

  reset() {
    window.clearTimeout(this.submitTimeout);
    this.loading = false;
    this.success = false;
    this.subAction = null;
  }
}
