import './reports.scss';

export class ReportsPage {
  constructor() {
    this.activeTab = 'my-reports';
  }

  changeTabs(event) {
    if(event.detail && event.detail.activeTab) {
      this.activeTab = event.detail.activeTab;
    }
  }
}
