/** @format */

import { bindable } from 'aurelia-framework';
import reportsService from 'services/api/reportsService';
import backgroundService from 'services/backgroundService';

import './reports-queue.scss';

const QUEUE_TASK_ID = 'reports_queue';

export class ReportsQueue {
  @bindable autoLoadId;
  @bindable onReportSelect;

  constructor() {
    this.reportsService = reportsService;

    //
    this.reports = [];
    this.onItemClick = this.onItemClick.bind(this);
  }

  autoLoadIdChanged(id) {
    this.autoSelectId(id, true);
  }

  attached() {
    this.loadQueue();
    backgroundService.registerTask(
      QUEUE_TASK_ID,
      this.loadQueue.bind(this),
      8 * 1000
    );
  }

  loadQueue() {
    this.reportsService.getExecutions().then(reports => {
      this.reports = reports.reverse();
      this.autoSelectId(this.autoLoadId);
    });
  }

  detached() {
    backgroundService.stopTask(QUEUE_TASK_ID);
  }

  onItemClick(report) {
    if (this.onReportSelect) {
      this.onReportSelect(report);
    }
  }

  autoSelectId(id, reload) {
    if (!id) return;

    let report = id && this.reports.find(x => x.id === id);

    if (report && report.ready) {
      this.onItemClick(report);
      this.autoLoadId = null;
      return;
    }

    if (reload) {
      this.loadQueue();
    }
  }
}
